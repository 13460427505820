import { getRequest } from "../../api/methodsCfg";

export const getAllUsers = async () => {
  const response = await getRequest("/user/all");
  return response;
};

export const getDevelopers = async () => {
  const response = await getRequest("/user/developers");
  return response;
};

export const getUsersCount = async () => {
  const response = await getRequest("/user/count");
  return response;
};

export const searchUsers = async (query) => {
  const response = await getRequest(`/user/searchByAdmin/${query}`);
  return response;
};
