import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { useCreatedDate } from "../../../hooks/useCreatedDate";
import useApi from "../../../hooks/useApi";
import { getRestaurantSuccessfulOrders } from "../../../services/orderService";
import {
  getRestaurantPartners,
  getRestaurantRatings,
  postRestaurantCustomers,
  getManagerRestaurants,
} from "../../../services/restaurantService";
import { subWeeks, max } from "date-fns";
import DatePickerButton from "../../buttons/DatePickerButton";
import Tag from "../../utils/Tag";
import { formatDate, formatMoney } from "../../../utils/formatting";
import Button from "../../buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons";
import { faStar, faStarHalfAlt } from "@fortawesome/pro-solid-svg-icons";
import useLangNavigate from "../../../hooks/useLangNavigate";
import { setUserRestaurants } from "../../../redux/slices/userSlice";
import Spinner from "../../utils/Spinner";

export const RestaurantOverview = ({ tKey = "dashboard.restaurant." }) => {
  usePageTitle("restaurantOverview");
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [uniqueConsumptionModes, setUniqueConsumptionModes] = useState([]);
  const [selectedConsumptionMode, setSelectedConsumptionMode] = useState(null);
  const [customers, setCustomers] = useState([]);
  const { request: getSuccessfulOrdersRequest, loading: ordersLoading } =
    useApi(getRestaurantSuccessfulOrders);
  const { request: getRatingsRequest, loading: ratingsLoading } =
    useApi(getRestaurantRatings);
  const { request: getPartnersRequest, loading: partnersLoading } = useApi(
    getRestaurantPartners
  );
  const { request: getCustomersRequest, loading: customersLoading } = useApi(
    postRestaurantCustomers
  );
  const { request: getManagerRestaurantsRequest } = useApi(
    getManagerRestaurants
  );
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const userId = useSelector((state) => state.user.userId);
  const { restaurantCreatedAt } = useCreatedDate(restaurantId);
  const endDate = new Date();
  const weekAgo = subWeeks(endDate, 1);
  const startDate = max([weekAgo, restaurantCreatedAt]); // 1 week ago or restaurant creation date if created less than a week ago
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const ordersComposition = useMemo(() => {
    if (!filteredOrders) return [];
    const groupedByItems = filteredOrders.reduce((acc, order) => {
      ["dishes", "menus", "todaysSpecials"].forEach((category) => {
        order[category].forEach((orderItem) => {
          let key = "dish";
          if (category === "menus") key = "menu";
          if (category === "todaysSpecials") key = "todaysspecial";
          const name = orderItem[key].name;
          acc[name] = (acc[name] || 0) + 1;
        });
      });
      return acc;
    }, {});
    return Object.entries(groupedByItems)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count);
  }, [filteredOrders]);
  const totalSales = useMemo(() => {
    if (filteredOrders)
      return filteredOrders.reduce((acc, item) => {
        return acc + item.totalPrice;
      }, 0);
  }, [filteredOrders]);
  const averageRating = useMemo(() => {
    if (Object.keys(ratings).length === 0) return 0;
    const total = Object.values(ratings).reduce((acc, value) => {
      if (typeof value === "number") return acc + value;
      return acc;
    }, 0);
    return (total / Object.values(ratings).length).toPrecision(2);
  }, [ratings]);
  const salesChartData = useMemo(() => {
    if (!filteredOrders) return;
    const groupedByDate = filteredOrders.reduce((acc, item) => {
      const date = formatDate(item.startAt.split("T")[0], false);
      acc[date] = (acc[date] || 0) + formatMoney(item.totalPrice);
      return acc;
    }, {});
    return {
      categories: Object.keys(groupedByDate),
      series: Object.values(groupedByDate),
    };
  }, [filteredOrders]);
  const salesChartOptions = {
    chart: {
      type: "bar",
      width: "100%",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: salesChartData?.categories,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: 0,
              to: 10000,
              color: "#1AE170",
            },
          ],
        },
      },
    },
  };
  const customersChartOptions = {
    chart: {
      type: "donut",
      width: "100%",
    },
    labels: [
      t(`${tKey}existingCustomers`, { count: customers.existing }),
      t(`${tKey}newCustomers`, { count: customers.new }),
    ],
    colors: ["#323232", "#1AE170"],
    legend: {
      position: "bottom",
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const fetchOrdersRestaurant = useCallback(
    async (restaurantId, startAt, endAt) => {
      await getSuccessfulOrdersRequest(restaurantId, startAt, endAt, true)
        .then((response) => {
          const consumptionMap = new Map();
          response.data.forEach((order) => {
            if (
              order.consumptionmode &&
              !consumptionMap.has(order.consumptionmode.consumptionModeId)
            )
              consumptionMap.set(
                order.consumptionmode.consumptionModeId,
                order.consumptionmode
              );
          });
          setUniqueConsumptionModes(
            Array.from(consumptionMap.values()).map((consumptionMode) => ({
              value: consumptionMode.consumptionModeId,
              label: t(`utils.consumptionmode.${consumptionMode.label}`),
            }))
          );
          setOrders(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getSuccessfulOrdersRequest, setUniqueConsumptionModes, setOrders, t]
  );

  const fetchRatings = useCallback(
    async (restaurantId) => {
      await getRatingsRequest(restaurantId)
        .then((response) => {
          setRatings(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getRatingsRequest, setRatings]
  );

  const fetchPartners = useCallback(
    async (restaurantId) => {
      await getPartnersRequest(restaurantId)
        .then((response) => {
          setPartners(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getPartnersRequest, setPartners]
  );

  const fetchCustomers = useCallback(
    async (restaurantId, startAt, endAt) => {
      await getCustomersRequest(restaurantId, startAt, endAt)
        .then((response) => {
          setCustomers(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getCustomersRequest, setCustomers]
  );

  const filterOrders = useCallback(() => {
    if (!orders) return [];
    let filteredOrders = [...orders];
    if (selectedConsumptionMode)
      filteredOrders = filteredOrders.filter(
        (o) =>
          o.consumptionmode.consumptionModeId === selectedConsumptionMode.value
      );
    return filteredOrders;
  }, [orders, selectedConsumptionMode]);

  const onDateRangeChange = (range) => {
    setDateRange([range]);
  };

  const fetchManagerRestaurants = useCallback(
    async (userId) => {
      await getManagerRestaurantsRequest(userId)
        .then((response) => {
          dispatch(setUserRestaurants(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getManagerRestaurantsRequest, dispatch]
  );

  useEffect(() => {
    fetchManagerRestaurants(userId);
    if (!restaurantId) return;
    fetchPartners(restaurantId);
    fetchRatings(restaurantId);
  }, [
    fetchManagerRestaurants,
    fetchPartners,
    fetchRatings,
    restaurantId,
    userId,
  ]);

  useEffect(() => {
    if (!restaurantId) return;
    fetchOrdersRestaurant(
      restaurantId,
      dateRange[0]?.startDate,
      dateRange[0]?.endDate
    );
    fetchCustomers(
      restaurantId,
      dateRange[0]?.startDate,
      dateRange[0]?.endDate
    );
  }, [restaurantId, dateRange, fetchOrdersRestaurant, fetchCustomers]);

  useEffect(() => {
    const filteredOrders = filterOrders();
    setFilteredOrders(filteredOrders);
  }, [orders, selectedConsumptionMode, filterOrders]);

  if (ordersLoading || ratingsLoading || partnersLoading || customersLoading)
    return <Spinner />;

  return (
    <div className="dashboard-content flex flex-wrap gap-2">
      <div className="w-70 bg-white rounded p-3 resto-overview-item">
        <div className="flex align-center justify-between">
          <div className="p1">{`${t(`${tKey}sales`)}: ${formatMoney(
            totalSales
          )}`}</div>
          <div className="flex gap-1">
            <DatePickerButton
              variant="light"
              onDateRangeChange={onDateRangeChange}
              minDate={restaurantCreatedAt}
              startAt={dateRange[0]?.startDate || null}
              endAt={dateRange[0]?.endDate || null}
            />
            <Select
              className="react-custom-select dashboard-select bg-light"
              classNamePrefix="react-select"
              placeholder={t(`${tKey}consumptionMode`)}
              options={uniqueConsumptionModes}
              value={selectedConsumptionMode}
              isClearable
              isSearchable={false}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(value) => setSelectedConsumptionMode(value)}
            />
          </div>
        </div>
        {salesChartData ? (
          <ReactApexChart
            height={"80%"}
            options={salesChartOptions}
            series={[
              { name: t(`${tKey}salesShort`), data: salesChartData.series },
            ]}
            type="bar"
          />
        ) : (
          <div className="self-align-center italic">
            {t("utils.errors.noData.title")}
          </div>
        )}
      </div>
      <div className="flex column bg-white flex-grow rounded p-3 resto-overview-item">
        <div className="flex justify-between p1">
          <span>{`${t(`${tKey}partners`)}`}</span>
          <span>{partners.length}</span>
        </div>
        <div className="flex column">
          {partners.length ? (
            partners
              .map((partner, index) => (
                <div key={index} className="flex gap-2 my-4">
                  <Tag variant="dark" text={index + 1} />
                  <span>{partner.name}</span>
                </div>
              ))
              .slice(0, 4)
          ) : (
            <div className="self-align-center italic">
              {t("utils.errors.noData.title")}
            </div>
          )}
        </div>
        <div className="mt-auto ml-auto">
          <Button
            className="text-light"
            link
            onClick={() => navigate("partners")}
          >
            {t(`${tKey}viewAllPartners`)}
            <FontAwesomeIcon className="ml-2" icon={faArrowRightLong} />
          </Button>
        </div>
      </div>
      <div className="flex column w-40 bg-white rounded p-3 resto-overview-item">
        <div className="flex justify-between p1">
          <span>{`${t(`${tKey}items`)}`}</span>
          <span>
            {ordersComposition.reduce((acc, item) => {
              return acc + item.count;
            }, 0)}
          </span>
        </div>
        <div className="h-100 flex column justify-around">
          {ordersComposition.length ? (
            ordersComposition
              .map((item, index) => (
                <div key={index} className="flex gap-2">
                  <Tag variant="dark" text={index + 1} />
                  <span>{item.name}</span>
                  <span className="ml-auto">{item.count}</span>
                </div>
              ))
              .slice(0, 6)
          ) : (
            <div className="self-align-center italic">
              {t("utils.errors.noData.title")}
            </div>
          )}
        </div>
      </div>
      <div className="bg-white flex-grow rounded p-3 resto-overview-item">
        <div className="flex justify-between p1">
          <span>{`${t(`${tKey}ratings`)}`}</span>
          <span>{averageRating}</span>
        </div>
        <div>
          {ratings ? (
            Object.entries(ratings).map(([key, value], index) => (
              <div key={index} className="flex gap-2 my-4 align-center">
                <Tag variant="dark" text={index + 1} />
                <span>{t(`utils.ratings.${key}`)}</span>
                <span className="ml-auto">
                  {[...Array(Math.floor(value))].map((_, i) => (
                    <FontAwesomeIcon
                      size="xs"
                      color="gold"
                      key={i}
                      icon={faStar}
                    />
                  ))}
                  {value % 1 >= 0.5 && (
                    <FontAwesomeIcon
                      size="sm"
                      color="gold"
                      icon={faStarHalfAlt}
                    />
                  )}
                </span>
              </div>
            ))
          ) : (
            <div className="self-align-center italic">
              {t("utils.errors.noData.title")}
            </div>
          )}
        </div>
      </div>
      <div className="bg-white flex-grow rounded p-3 resto-overview-item">
        <div className="flex justify-between p1">
          <span>{`${t(`${tKey}customers`)}`}</span>
          <span>{customers.existing + customers.new || 0}</span>
        </div>
        {customers ? (
          <ReactApexChart
            options={customersChartOptions}
            series={[customers.existing || 0, customers.new || 0]}
            type="pie"
          />
        ) : (
          <div className="self-align-center italic">
            {t("utils.errors.noData.title")}
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantOverview;
