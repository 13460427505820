//import React, { useEffect, useState, useMemo, useCallback } from "react";
//import useLangNavigate from "../../../hooks/useLangNavigate";
import { usePageTitle } from "../../../hooks/useMeta";
//import { useTranslation } from "react-i18next";
//import { getDevelopers } from "../../../services/admin/userService";
//import useApi from "../../../hooks/useApi";

const AdminDevelopers = () => {
  usePageTitle("adminDevelopers");
  /*const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [query, setQuery] = useState("");
  const { request: getDevelopersRequest } = useApi(getDevelopers);
  const { t } = useTranslation();
  const navigate = useLangNavigate();

  useEffect(() => {
    getDevelopersRequest().then((response) => {
      setUsers(response.data);
    });
  }, [getDevelopersRequest]);

  useEffect(() => {
    if (query) {
      setFilteredUsers(
        users.filter(
          (user) =>
            user.username.toLowerCase().includes(query.toLowerCase()) ||
            user.email.toLowerCase().includes(query.toLowerCase()) ||
            user.firstname.toLowerCase().includes(query.toLowerCase()) ||
            user.lastname.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }, [query, users]);
*/
  return <div>AdminDevelopers</div>;
};

export default AdminDevelopers;
