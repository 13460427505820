import React, { useEffect, useState, useMemo, useCallback } from "react";
import Select from "react-select";
import { searchUsers, getAllUsers } from "../../../services/admin/userService";
import { getListOptions } from "../../../services/listService";
import useApi from "../../../hooks/useApi";
import useLangNavigate from "../../../hooks/useLangNavigate";
import { usePageTitle } from "../../../hooks/useMeta";
import Table from "../../table/Table";
import DropdownButton from "../../buttons/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import DecoratedInput from "../../inputs/DecoratedInput";
import { useTranslation } from "react-i18next";
import NoData from "../../errors/NoData";
import Spinner from "../../utils/Spinner";

export const AdminUsers = ({ tKey = "dashboard.admin.user." }) => {
  usePageTitle("adminUsers");
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const { request: getUsersRequest, loading: usersLoading } =
    useApi(getAllUsers);
  const { request: searchUsersRequest, loading: searchLoading } =
    useApi(searchUsers);
  const { request: getRolesRequest } = useApi(getListOptions);
  const { t } = useTranslation();
  const navigate = useLangNavigate();

  useEffect(() => {
    getUsersRequest().then((response) => {
      setUsers(response.data);
    });
    getRolesRequest("role").then((response) => {
      setUniqueRoles(
        response.data.map((role) => ({
          value: role.roleId,
          label: t(`utils.roles.${role.label}`),
        }))
      );
    });
  }, [getUsersRequest, getRolesRequest, t]);

  useEffect(() => {
    if (query) {
      searchUsersRequest(query).then((response) => {
        setUsers(
          selectedRole
            ? response.data.filter((user) =>
                user.roles.some((role) => role.roleId === selectedRole.value)
              )
            : response.data
        );
      });
    } else {
      getUsersRequest().then((response) => {
        setUsers(
          selectedRole
            ? response.data.filter((user) =>
                user.roles.some((role) => role.roleId === selectedRole.value)
              )
            : response.data
        );
      });
    }
  }, [query, selectedRole, searchUsersRequest, getUsersRequest]);

  const handleUserEdit = useCallback((user) => {
    console.log(user);
  }, []);

  const handleUserDelete = useCallback((user) => {
    console.log(user);
  }, []);

  const columns = useMemo(() => {
    let cols = [
      {
        accessor: "userId",
        Cell: "",
      },
      {
        Header: t(`${tKey}image`),
        Cell: ({ row }) =>
          row.original.fullImagePath ? (
            <img
              className="list-preview-img"
              src={row.original.fullImagePath}
              alt={`${row.original.firstname} ${row.original.lastname}`}
              height={50}
            />
          ) : (
            "No image"
          ),
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}name`),
        accessor: (row) => `${row.firstname} ${row.lastname}`,
      },
      {
        Header: t(`${tKey}contact`),
        accessor: (row) => (
          <div className="flex column gap-1">
            <a href={`mailto:${row.email}`}>{row.email}</a>
            <span>{row.phone}</span>
          </div>
        ),
      },
      {
        Header: t(`${tKey}roles`),
        accessor: (row) =>
          row.roles.length > 0
            ? row.roles.map((role) => t(`utils.roles.${role.label}`)).join(", ")
            : t(`utils.roles.noRole`),
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <DropdownButton
            actions={[
              {
                label: t(`${tKey}actions.details`),
                onClick: () =>
                  navigate(`dashboard/admin-users/${row.original.userId}`),
              },
              {
                label: t(`${tKey}actions.edit`),
                onClick: () => handleUserEdit(row.original),
              },
              {
                label: t(`${tKey}actions.delete`),
                onClick: () => handleUserDelete(row.original),
              },
            ]}
          >
            <FontAwesomeIcon color="grey" icon={faEllipsisVertical} />
          </DropdownButton>
        ),
        disableSortBy: true,
      },
    ];
    return cols;
  }, [t, tKey, handleUserEdit, handleUserDelete, navigate]);

  if (usersLoading) return <Spinner />;

  return (
    <div className="dashboard-content">
      <div className="flex gap-1 search-module">
        <DecoratedInput
          className="pl-2 bg-white dashboard-search-bar flex-grow"
          input={{
            id: "searchProduct",
            class: "p4",
            value: "",
            placeholder: t(`${tKey}searchPlaceholder`),
          }}
          hasResearchButton
          onResearchButtonClick={(query) => {
            setQuery(query);
          }}
        />
        <Select
          className="react-custom-select dashboard-select"
          classNamePrefix="react-select"
          placeholder={t(`${tKey}role`)}
          options={uniqueRoles}
          value={selectedRole}
          isClearable
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(value) => setSelectedRole(value)}
        />
      </div>
      <div className="table-container mt-4">
        {searchLoading ? (
          <Spinner />
        ) : users && users.length > 0 ? (
          <Table
            data={users}
            columns={columns}
            onClickRow={(row) =>
              navigate(`dashboard/admin-users/${row.original.userId}`)
            }
            rowClassName="p-2"
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
