import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  putReadNotification,
  getNotifications,
} from "../../services/userService";
import Button from "../buttons/Button";
import {
  faExclamation,
  faExclamationCircle,
  faTriangleExclamation,
} from "@fortawesome/pro-duotone-svg-icons";
import { formatDate } from "../../utils/formatting";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const NotificationsList = ({
  tKey = "utils.notifications.",
  userId,
  closeNotifications = () => {},
}) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [showOldNotifications, setShowOldNotifications] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getNotifications(userId);
      setNotifications(
        response.data.filter((notification) => notification.isRead)
      );
      setUnreadNotifications(
        response.data.filter((notification) => !notification.isRead)
      );
    };
    fetchNotifications();
  }, [userId]);

  useEffect(() => {
    return () => {
      unreadNotifications.forEach(async (notification) => {
        await putReadNotification(notification.notificationId);
      });
    };
  }, [unreadNotifications]);

  const getNotificationIcon = (notification) => {
    const typeId = notification.notificationtype.notificationTypeId;
    const iconClass = `text-${
      notification.isRead ? "dark" : notification.notificationtype.variant
    }`;
    switch (typeId) {
      case 1:
        return (
          <FontAwesomeIcon className={iconClass} icon={faExclamationCircle} />
        );
      case 2:
        return (
          <FontAwesomeIcon className={iconClass} icon={faTriangleExclamation} />
        );
      case 3:
        return (
          <FontAwesomeIcon className={iconClass} icon={faTriangleExclamation} />
        );
      default:
        return <FontAwesomeIcon className={iconClass} icon={faExclamation} />;
    }
  };

  const getNotificationContent = (notification) => {
    return (
      <div className="w-100">
        <div className="mb-1 flex justify-between align-center">
          <p className="p1 mr-2">{notification.title}</p>
          <p className="text-light p4">{formatDate(notification.createdAt)}</p>
        </div>
        <p dangerouslySetInnerHTML={{ __html: notification.description }} />
      </div>
    );
  };

  return (
    <>
      {notifications.length ? (
        <>
          <div className="p1 notifications-header flex justify-between align-center">
            <div className="p1">
              {t(`${tKey}header`, { count: unreadNotifications.length || 0 })}
            </div>
            <Button link onClick={closeNotifications}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
          <div className="notifications-container">
            {unreadNotifications.length > 0 ? (
              <div className="notifications-list">
                {unreadNotifications.map((notification) => (
                  <div
                    key={notification.notificationId}
                    className={`flex gap-2 notification-item unread-notification notification-${
                      notification.notificationtype.variant || "primary"
                    }`}
                  >
                    {getNotificationIcon(notification)}
                    {getNotificationContent(notification)}
                  </div>
                ))}
              </div>
            ) : (
              <div className="my-3 text-center text-light">
                {t(`${tKey}noNotifications`)}
              </div>
            )}
            {showOldNotifications && (
              <div className="notifications-list">
                {notifications.map((notification) => (
                  <div
                    key={notification.notificationId}
                    className={`flex gap-2 notification-item read-notification`}
                  >
                    {getNotificationIcon(notification)}
                    {getNotificationContent(notification)}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex">
            <Button
              className="ml-auto text-light"
              link
              onClick={() => setShowOldNotifications(!showOldNotifications)}
            >
              {t(
                `${tKey}${
                  !showOldNotifications
                    ? "showOldNotifications"
                    : "hideOldNotifications"
                }`,
                { count: notifications.length }
              )}
            </Button>
          </div>
        </>
      ) : (
        <p className="p-4 p2 text-center text-light italic">
          {t(`${tKey}noNotifications`)}
        </p>
      )}
    </>
  );
};

export default NotificationsList;
