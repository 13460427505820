import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getCompany,
  getCompanyOrders,
} from "../../../../services/admin/companyService";
import ReactApexChart from "react-apexcharts";
import useApi from "../../../../hooks/useApi";
import useLangNavigate from "../../../../hooks/useLangNavigate";
import NoData from "../../../errors/NoData";
import Table from "../../../table/Table";
import Button from "../../../buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { format, parse } from "date-fns";
import { createCompanyIcon, createRestaurantIcon } from "../../../../utils/gis";
import { startControllingCompany } from "../../../../redux/slices/adminSlice";
import { useDispatch } from "react-redux";
import Spinner from "../../../utils/Spinner";

const CompanyView = ({ tKey = "dashboard.companyView." }) => {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { request: getCompanyRequest, loading } = useApi(getCompany);
  const { request: getCompanyOrdersRequest } = useApi(getCompanyOrders);
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);
  const [ordersChartSeries, setOrdersChartSeries] = useState([]);
  const [ordersChartLabels, setOrdersChartLabels] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const mapRef = useRef(null);
  const [center, setCenter] = useState([46.8307489, 8.3252039]);
  const [zoom, setZoom] = useState(8);
  const dispatch = useDispatch();

  const setMapView = useCallback((x, y, newZoom) => {
    if (!mapRef.current) return;
    mapRef.current.setView([x, y], newZoom);
  }, []);

  useEffect(() => {
    getCompanyRequest(companyId).then((res) => {
      setCompany(res.data);
      setMapView(res.data.x, res.data.y, 13);
    });
    getCompanyOrdersRequest(companyId).then((res) => {
      const rawOrders = res.data.users.flatMap((user) => user.orders);
      const groupedOrders = {};
      rawOrders.forEach((order) => {
        const date = format(new Date(order.startAt), "MMM yyyy");
        if (!groupedOrders[date]) groupedOrders[date] = [];
        groupedOrders[date].push(order);
      });

      const sortedKeys = Object.keys(groupedOrders).sort((a, b) => {
        const yearA = a.split(" ")[1],
          monthA = a.split(" ")[0];
        const yearB = b.split(" ")[1],
          monthB = b.split(" ")[0];
        const dateA = parse(`${monthA} ${yearA}`, "MMM yyyy", new Date());
        const dateB = parse(`${monthB} ${yearB}`, "MMM yyyy", new Date());
        return dateA - dateB;
      });

      const chartData = sortedKeys.reduce(
        (acc, key) => {
          const monthTotal = groupedOrders[key].length;
          const monthAmount = groupedOrders[key].reduce(
            (acc, order) => acc + order.totalPrice,
            0
          );
          acc.cumulativeTotalData.push((acc.cumulativeTotal || 0) + monthTotal);
          acc.cumulativeAmountData.push(
            (acc.cumulativeAmount || 0) + monthAmount
          );
          acc.monthlyTotalData.push(monthTotal);
          acc.monthlyAmountData.push(monthAmount);
          acc.cumulativeTotal += monthTotal;
          acc.cumulativeAmount += monthAmount;
          return acc;
        },
        {
          cumulativeTotalData: [],
          cumulativeAmountData: [],
          monthlyTotalData: [],
          monthlyAmountData: [],
          cumulativeTotal: 0,
          cumulativeAmount: 0,
        }
      );

      setOrdersChartSeries([
        {
          name: t(`${tKey}orders.totalCumul`),
          type: "line",
          data: chartData.cumulativeTotalData,
        },
        {
          name: t(`${tKey}orders.amountCumul`),
          type: "line",
          data: chartData.cumulativeAmountData,
        },
        {
          name: t(`${tKey}orders.totalMonthly`),
          type: "column",
          data: chartData.monthlyTotalData,
        },
        {
          name: t(`${tKey}orders.amountMonthly`),
          type: "column",
          data: chartData.monthlyAmountData,
        },
      ]);
      setOrdersChartLabels(sortedKeys);
      setTotalOrdersCount(chartData.cumulativeTotal);
    });
  }, [
    t,
    tKey,
    companyId,
    setMapView,
    getCompanyRequest,
    getCompanyOrdersRequest,
  ]);

  const ordersChartOptions = useMemo(
    () => ({
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [4, 4, 0, 0],
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: ordersChartLabels,
      xaxis: {
        type: "category",
        categories: ordersChartLabels,
        tickPlacement: "on",
      },
      yaxis: [
        {
          title: {
            text: t(`${tKey}orders.count`),
            style: {
              color: "#FFC117",
            },
          },
          labels: {
            formatter: (val) => `${parseInt(val, 10)}`,
            style: {
              colors: "#FFC117",
            },
          },
        },
        {
          opposite: true,
          title: {
            text: t(`${tKey}orders.sellings`),
            style: {
              color: "#5e8347",
            },
          },
          labels: {
            formatter: (val) => `${parseFloat(val).toFixed(2)} CHF`,
            style: {
              colors: "#5e8347",
            },
          },
        },
      ],
      tooltip: {
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return parseInt(val, 10);
          },
        },
      },
      colors: ["#b39a10", "#5e8347", "#FFC117", "#85BB65"],
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: -10,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    }),
    [ordersChartLabels, t, tKey]
  );

  const partnersColumns = useMemo(
    () => [
      {
        accessor: "offerId",
        Cell: "",
      },
      {
        Header: t(`${tKey}name`),
        accessor: (row) => row.restaurant.name,
      },
      {
        Header: t(`${tKey}address`),
        accessor: (row) => (
          <div className="flex column">
            <span>{row.restaurant.address}</span>
            <span className="p3">{`${row.restaurant.city.npa} ${row.restaurant.city.label}`}</span>
          </div>
        ),
      },
      {
        Header: t(`${tKey}offer`),
        accessor: (row) => t(`utils.offerTypes.${row.offertype.label}`),
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <Button
            variant="transparent"
            icon
            onClick={() =>
              navigate(
                `dashboard/admin-restaurants/${row.original.restaurantId}`
              )
            }
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [t, tKey, navigate]
  );

  function LocationMarker() {
    const map = useMapEvents({
      moveend: () => {
        setCenter(map.getCenter());
        setZoom(map.getZoom());
      },
    });

    return null;
  }

  const handleCompanyControl = useCallback(
    (company) => {
      dispatch(startControllingCompany(company));
      navigate("dashboard");
    },
    [dispatch, navigate]
  );

  if (loading) return <Spinner />;

  return (
    <div className="dashboard-content flex column gap-2 overflow-y-scroll">
      <div className="flex justify-between">
        <div className="flex column">
          <h2 className="m-0 flex gap-2">
            <span>{company?.name}</span>
            <Button size="sm" onClick={() => handleCompanyControl(company)}>
              {t(`${tKey}takeControl`)}
            </Button>
          </h2>
          <div>{t(`${tKey}employees`, { count: company?.users.length })}</div>
          <div className="flex column p3">
            <a href={`mailto:${company?.email}`}>{company?.email}</a>
            <span>{company?.phone}</span>
            <span>{`${company?.address}, ${company?.city.npa} ${company?.city.label}`}</span>
          </div>
        </div>
        {!!company?.imagePath && (
          <img height={100} src={company?.fullImagePath} alt="Company Logo" />
        )}
      </div>
      <div className="col flex gap-2">
        <div className="col-6 flex column bg-white rounded p-3">
          <div className="col bg-white rounded p-3">
            <h3 className="m-0">
              {t(`${tKey}partners`, {
                count: company?.restaurantOffers.length,
              })}
            </h3>
            {company?.restaurantOffers.length > 0 ? (
              <Table
                columns={partnersColumns}
                data={company?.restaurantOffers}
                rowClassName="p-2"
                onClickRow={(row) =>
                  navigate(
                    `dashboard/admin-restaurants/${row.original.restaurantId}`
                  )
                }
              ></Table>
            ) : (
              <div>{<NoData />}</div>
            )}
          </div>
          <div className="col bg-white rounded p-3">
            <h3 className="m-0">{t(`${tKey}orders.title`)}</h3>
            <ReactApexChart
              key={totalOrdersCount}
              options={ordersChartOptions}
              series={ordersChartSeries}
              type="line"
              height={300}
            />
          </div>
        </div>
        <div className="col-6 flex column bg-white rounded p-3">
          <MapContainer
            ref={mapRef}
            center={center}
            zoom={zoom}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
              OpenStreetMap</a> contributors'
            />
            <LocationMarker />
            {!!company && (
              <Marker
                icon={createCompanyIcon()}
                position={[company.x, company.y]}
              >
                <Popup>{company?.name}</Popup>
              </Marker>
            )}
            {!!company &&
              company.restaurantOffers.map((offer) => (
                <Marker
                  icon={createRestaurantIcon()}
                  key={offer.restaurantId}
                  position={[offer.restaurant.x, offer.restaurant.y]}
                >
                  <Popup>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `dashboard/admin-restaurants/${offer.restaurant.restaurantId}`
                        )
                      }
                    >
                      {offer.restaurant.name}
                    </div>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default CompanyView;
