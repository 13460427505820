import React, { useState, useMemo, useEffect, useCallback } from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import useApi from "../../../hooks/useApi";
import Button from "../../buttons/Button";
import DecoratedInput from "../../inputs/DecoratedInput";
import NoData from "../../errors/NoData";
import Table from "../../table/Table";
import Modal from "../../utils/Modal";
import {
  getCompanyPartners,
  getAvailableOffers,
  subscribeToOffer,
  unsubscribeToOffer,
} from "../../../services/companyService";
import { getRestaurant } from "../../../services/restaurantService";
import { formatPhoneNumber } from "../../../utils/formatting";
import Spinner from "../../utils/Spinner";

export const CompanyPartners = ({ tKey = "dashboard.partners." }) => {
  usePageTitle("companyPartners");
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const [query, setQuery] = useState("");
  const [partners, setPartners] = useState([]);
  const [offers, setOffers] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const companyId = useSelector((state) => state.user.companyId);
  const { request: getPartnersRequest, loading: partnersLoading } =
    useApi(getCompanyPartners);
  const { request: getOffersRequest, loading: offersLoading } =
    useApi(getAvailableOffers);
  const { request: subscribeToOfferRequest } = useApi(subscribeToOffer);
  const { request: unsubscribeToOfferRequest } = useApi(unsubscribeToOffer);
  const { request: getRestaurantRequest } = useApi(getRestaurant);

  const fetchPartners = useCallback(
    async (companyId) => {
      await getPartnersRequest(companyId)
        .then((res) => {
          setPartners(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getPartnersRequest]
  );

  const fetchOffers = useCallback(
    async (companyId) => {
      await getOffersRequest(companyId)
        .then((res) => {
          setOffers(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [getOffersRequest]
  );

  const handleSubscribe = useCallback(
    async (offer) => {
      await subscribeToOfferRequest(companyId, offer.offerId)
        .then(() => {
          toast.success(t(`${tKey}subscribeSuccess`));
          fetchPartners(companyId);
          fetchOffers(companyId);
        })
        .catch((error) => {
          toast.error(t(`${tKey}subscribeError`));
          console.error(error);
        });
    },
    [companyId, fetchPartners, fetchOffers, t, tKey, subscribeToOfferRequest]
  );

  const handleUnsubscribe = useCallback(
    async (offer) => {
      await unsubscribeToOfferRequest(companyId, offer.offerId)
        .then(() => {
          toast.success(t(`${tKey}unsubscribeSuccess`));
          fetchPartners(companyId);
          fetchOffers(companyId);
        })
        .catch((error) => {
          toast.error(t(`${tKey}unsubscribeError`));
          console.error(error);
        });
    },
    [companyId, fetchPartners, fetchOffers, unsubscribeToOfferRequest, t, tKey]
  );

  const getActions = useCallback(
    (offer) => {
      const isSubscribed = offer.partners.some(
        (company) => company.companyId === companyId
      );
      return !isSubscribed ? (
        <Button
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            handleSubscribe(offer);
          }}
        >
          {t(`${tKey}subscribe`)}
        </Button>
      ) : (
        <Button
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            handleUnsubscribe(offer);
          }}
        >
          {t(`${tKey}unsubscribe`)}
        </Button>
      );
    },
    [companyId, handleSubscribe, handleUnsubscribe, t, tKey]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "partnerId",
        disableSortBy: true,
        Cell: "",
      },
      {
        Header: "Image",
        accessor: "restaurant.imagePath",
        Cell: ({ value }) =>
          value ? (
            <img src={value} alt="Dish" style={{ width: "50px" }} />
          ) : (
            "No image"
          ),
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}restaurantName`),
        accessor: "restaurant.name",
      },
      {
        Header: t(`${tKey}type`),
        accessor: "offertype.label",
        Cell: ({ row }) =>
          t(`utils.offerTypes.${row.original.offertype.label}`),
      },
      {
        Header: t(`${tKey}address`),
        accessor: "address",
        Cell: ({ row }) => (
          <>
            <div>{row.original.restaurant.address}</div>
            <div className="text-light">{`${row.original.restaurant.city.npa} ${row.original.restaurant.city.label}`}</div>
          </>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }) => getActions(row.original),
      },
    ],
    [getActions, t, tKey]
  );

  const filterPartnersAndOffers = useCallback(
    (query) => {
      const filteredPartners = partners.filter((partner) => {
        return partner.restaurant.name
          .toLowerCase()
          .includes(query.toLowerCase());
      });
      const filteredOffers = offers.filter((offer) => {
        return offer.restaurant.name
          .toLowerCase()
          .includes(query.toLowerCase());
      });
      setFilteredPartners(filteredPartners);
      setFilteredOffers(filteredOffers);
    },
    [partners, offers]
  );

  useEffect(() => {
    fetchPartners(companyId);
    fetchOffers(companyId);
  }, [companyId, fetchPartners, fetchOffers]);

  useEffect(() => {
    filterPartnersAndOffers(query);
  }, [query, partners, offers, filterPartnersAndOffers]);

  const handleDetail = async (row) => {
    try {
      const response = await getRestaurantRequest(row.original.restaurantId);
      const restaurantData = response.data;
      setModalContent(
        <div className="flex align-center gap-3">
          <div>
            <img
              src={restaurantData.imagePath}
              alt={restaurantData.name || "restaurant"}
              size="100"
            />
          </div>
          <div className="flex-grow">
            <h5 className="my-0">{restaurantData.name}</h5>
            <div>
              <p className="my-1">{restaurantData.email}</p>
              <p className="my-1">{formatPhoneNumber(restaurantData.phone)}</p>
              <p className="my-1">{restaurantData.address}</p>
              <p className="my-1">{`${restaurantData.city.npa} ${restaurantData.city.label}`}</p>
            </div>
          </div>
        </div>
      );
      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  if (partnersLoading || offersLoading) return <Spinner />;

  return (
    <>
      <div className="dashboard-content w-100">
        <DecoratedInput
          className="bg-white pl-2 dashboard-search-bar w-100"
          input={{
            id: "searchOffers",
            class: "p4",
            value: "",
            placeholder: t(`${tKey}searchOffers`),
          }}
          hasResearchButton
          onResearchButtonClick={(query) => {
            setQuery(query);
          }}
        />
        <div className="flex gap-1 mt-2 company-partners-tables">
          <div className="w-50 bg-white p-3 rounded">
            <p className="m-0 p1">{t(`${tKey}active`)}</p>
            {filteredPartners?.length === 0 ? (
              <NoData />
            ) : (
              <Table
                rowClassName="p-4"
                columns={columns}
                data={filteredPartners}
                onClickRow={handleDetail}
              />
            )}
          </div>
          <div className="w-50 bg-white p-3 rounded">
            <p className="m-0 p1">{t(`${tKey}available`)}</p>
            {filteredOffers?.length === 0 ? (
              <NoData />
            ) : (
              <Table
                rowClassName="p-4"
                columns={columns}
                data={filteredOffers}
                onClickRow={handleDetail}
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} noFooter>
        {modalContent}
      </Modal>
    </>
  );
};

export default CompanyPartners;
