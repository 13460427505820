import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  faGripDotsVertical,
  faEllipsisVertical,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import useApi from "../../../hooks/useApi";
import useModal from "../../../hooks/useModal";
import Button from "../../buttons/Button";
import DecoratedInput from "../../inputs/DecoratedInput";
import NoData from "../../errors/NoData";
import Spinner from "../../utils/Spinner";
import Table from "../../table/Table";
import DownloadTable from "../../table/DownloadTable";
import DropdownButton from "../../buttons/DropdownButton";
import Modal from "../../utils/Modal";
import format from "date-fns/format";
import {
  getTodaysSpecials,
  deleteTodaysSpecial,
  duplicateTodaysSpecial,
  updateTodaysSpecialsOrder,
  switchActivateTodaysSpecial,
} from "../../../services/todaysSpecialService";
import { usePageTitle } from "../../../hooks/useMeta";
import useLangNavigate from "../../../hooks/useLangNavigate";
import { formatSwissPrice } from "../../../utils/formatting";

export const RestaurantTodaysSpecial = ({
  tKey = "dashboard.todaysSpecials.",
}) => {
  usePageTitle("restaurantTodaysSpecials");
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getTodaysSpecialsError,
    loading: getTodaysSpecialsLoading,
    request: getTodaysSpecialsRequest,
  } = useApi(getTodaysSpecials);
  const { request: deleteTodaysSpecialRequest } = useApi(deleteTodaysSpecial);
  const { request: duplicateTodaysSpecialRequest } = useApi(
    duplicateTodaysSpecial
  );
  const [query, setQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRecurrence, setSelectedRecurrence] = useState(null);
  const [selectedTodaysSpecial, setSelectedTodaysSpecial] = useState(null);
  const [todaysSpecials, setTodaysSpecials] = useState([]);
  const [filteredTodaysSpecials, setFilteredTodaysSpecials] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);

  const fetchRestaurantTodaysSpecials = useCallback(
    async (restaurantId) => {
      await getTodaysSpecialsRequest(restaurantId)
        .then((response) => {
          setTodaysSpecials(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getTodaysSpecialsRequest, setTodaysSpecials]
  );

  const handleDetail = useCallback(
    (row) => {
      navigate(`dashboard/todaysspecials/${row.original.todaysSpecialId}`);
    },
    [navigate]
  );

  const handleDuplicate = useCallback(
    async (todaysSpecial) => {
      await duplicateTodaysSpecialRequest(todaysSpecial)
        .then(() => {
          fetchRestaurantTodaysSpecials(restaurantId);
          toast.success(
            t(`${tKey}duplicate.success`, {
              todaysSpecialName: todaysSpecial.name,
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            t(`${tKey}duplicate.error`, {
              todaysSpecialName: todaysSpecial.name,
            })
          );
        });
    },
    [
      duplicateTodaysSpecialRequest,
      fetchRestaurantTodaysSpecials,
      restaurantId,
      t,
      tKey,
    ]
  );

  const handleDelete = useCallback(
    async (todaysSpecial) => {
      setSelectedTodaysSpecial(todaysSpecial);
      setModalContent(
        <>
          <h5 className="mt-0">{t(`${tKey}delete.title`)}</h5>
          <p>
            {t(`${tKey}delete.text`, { todaysSpecialName: todaysSpecial.name })}
          </p>
        </>
      );
      openModal();
    },
    [openModal, setSelectedTodaysSpecial, t, tKey]
  );

  const handleSwitchActiveTodaysSpecial = useCallback(
    async (event, todaySpecial) => {
      if (!todaySpecial.isPublished) return;
      event.stopPropagation();
      event.preventDefault();
      await switchActivateTodaysSpecial(todaySpecial.todaysSpecialId)
        .then(() => {
          fetchRestaurantTodaysSpecials(restaurantId);
          toast.success(
            t(
              `${tKey}status.${
                todaySpecial.isActive ? "deactivated" : "activated"
              }`,
              {
                todaySpecialName: todaySpecial.name,
              }
            )
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            t(
              `${tKey}status.${
                todaySpecial.isActive ? "deactivate" : "activate"
              }.error`,
              {
                todaySpecialName: todaySpecial.name,
              }
            )
          );
        });
    },
    [fetchRestaurantTodaysSpecials, restaurantId, t, tKey]
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <div>
            <FontAwesomeIcon
              icon={faInfoCircle}
              data-tooltip-id="infoTip"
              data-tooltip-html={t(`${tKey}order.info`)}
            />
            <Tooltip id="infoTip" place="bottom" effect="solid" />
          </div>
        ),
        accessor: "order",
        isDragHandle: true,
        Cell: () => <FontAwesomeIcon color="black" icon={faGripDotsVertical} />,
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}from`),
        accessor: "from",
        Cell: ({ value }) => format(new Date(value), "dd.MM.yyyy"),
      },
      {
        Header: t(`${tKey}to`),
        accessor: "to",
        Cell: ({ value }) => format(new Date(value), "dd.MM.yyyy"),
      },
      {
        Header: t(`${tKey}recurrence.header`),
        Cell: ({ row }) => {
          return (
            <div>
              {t(
                `${tKey}recurrence.${
                  row.original.isDailyRecurring
                    ? "daily"
                    : row.original.isWeeklyRecurring
                    ? "weekly"
                    : "once"
                }`
              )}
            </div>
          );
        },
      },
      {
        Header: t(`${tKey}title`),
        accessor: "name",
      },
      {
        Header: t(`${tKey}price`),
        accessor: "price",
        Cell: ({ row }) => {
          const { price, discount } = row.original;
          return discount ? (
            <>
              <div>
                {formatSwissPrice(price - (price * discount) / 100, true)}
              </div>
              <div className="strikethrough text-light p4 mt-1">
                {formatSwissPrice(price, true)}
              </div>
            </>
          ) : (
            price.toLocaleString("de-CH", {
              style: "currency",
              currency: "CHF",
            })
          );
        },
      },
      {
        Header: t(`${tKey}status.header`),
        Cell: ({ row }) => (
          <div
            onClick={(event) =>
              handleSwitchActiveTodaysSpecial(event, row.original)
            }
            className={`rounded text-white py-1 px-3 text-center fit-content ${
              !row.original.isPublished
                ? "bg-dark"
                : row.original.isActive
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {t(
              `${tKey}status.${
                !row.original.isPublished
                  ? "unpublished"
                  : row.original.isActive
                  ? "active"
                  : "inactive"
              }`
            )}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <DropdownButton
            actions={[
              {
                label: t(`${tKey}actions.edit`),
                onClick: () => handleDetail(row),
              },
              {
                label: t(`${tKey}actions.duplicate`),
                onClick: () => handleDuplicate(row.original),
              },
              {
                label: t(`${tKey}actions.delete`),
                onClick: () => handleDelete(row.original),
              },
            ]}
          >
            <FontAwesomeIcon color="grey" icon={faEllipsisVertical} />
          </DropdownButton>
        ),
        disableSortBy: true,
      },
    ],
    [
      handleSwitchActiveTodaysSpecial,
      handleDelete,
      handleDetail,
      handleDuplicate,
      t,
      tKey,
    ]
  );

  const statusOptions = useMemo(
    () => [
      { value: "active", label: t(`${tKey}status.active`) },
      { value: "inactive", label: t(`${tKey}status.inactive`) },
      { value: "unpublished", label: t(`${tKey}status.unpublished`) },
    ],
    [t, tKey]
  );

  const recurrenceOptions = useMemo(
    () => [
      { value: "daily", label: t(`${tKey}recurrence.daily`) },
      { value: "weekly", label: t(`${tKey}recurrence.weekly`) },
      { value: "once", label: t(`${tKey}recurrence.once`) },
    ],
    [t, tKey]
  );

  const goToAdd = () => {
    navigate("dashboard/todaysspecials/add");
  };

  const handleOrderChange = async (newOrder) => {
    await updateTodaysSpecialsOrder(
      restaurantId,
      newOrder.map((todaysSpecial) => todaysSpecial.todaysSpecialId)
    )
      .then((res) => {
        setTodaysSpecials(res.data);
        toast.success(t(`${tKey}order.success`));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}order.error`));
      });
  };

  const deleteSelectedTodaysSpecial = async () => {
    await deleteTodaysSpecialRequest(selectedTodaysSpecial.todaySpecialId)
      .then((res) => {
        if (res.status === 200) {
          setTodaysSpecials(
            todaysSpecials.filter(
              (t) => t.todaySpecialId !== selectedTodaysSpecial.todaySpecialId
            )
          );
          toast.success(
            t(`${tKey}delete.success`, {
              todaysSpecialName: selectedTodaysSpecial.name,
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          t(`${tKey}delete.error`, {
            todaysSpecialName: selectedTodaysSpecial.name,
          })
        );
      })
      .finally(async () => {
        setModalContent(null);
        setSelectedTodaysSpecial(null);
        closeModal();
        await fetchRestaurantTodaysSpecials(restaurantId);
      });
  };

  const filterTodaysSpecials = useCallback(
    (query) => {
      if (!todaysSpecials) return [];
      let filteredTodaysSpecials = [...todaysSpecials];
      if (selectedStatus)
        switch (selectedStatus.value) {
          case "active":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => t.isPublished && t.isActive
            );
            break;
          case "inactive":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => t.isPublished && !t.isActive
            );
            break;
          case "unpublished":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => !t.isPublished
            );
            break;
          default:
            break;
        }
      if (selectedRecurrence)
        switch (selectedRecurrence.value) {
          case "daily":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => t.isDailyRecurring && !t.isWeeklyRecurring
            );
            break;
          case "weekly":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => t.isWeeklyRecurring && !t.isDailyRecurring
            );
            break;
          case "once":
            filteredTodaysSpecials = filteredTodaysSpecials.filter(
              (t) => !t.isDailyRecurring && !t.isWeeklyRecurring
            );
            break;
          default:
            break;
        }
      if (query)
        filteredTodaysSpecials = filteredTodaysSpecials.filter((t) =>
          t.name.toLowerCase().includes(query.toLowerCase())
        );
      return filteredTodaysSpecials;
    },
    [todaysSpecials, selectedStatus, selectedRecurrence]
  );

  useEffect(() => {
    fetchRestaurantTodaysSpecials(restaurantId);
  }, [restaurantId, fetchRestaurantTodaysSpecials]);

  useEffect(() => {
    setFilteredTodaysSpecials(filterTodaysSpecials(query));
  }, [
    query,
    todaysSpecials,
    selectedStatus,
    selectedRecurrence,
    filterTodaysSpecials,
  ]);

  if (getTodaysSpecialsError) return <div>Error: {getTodaysSpecialsError}</div>;
  if (getTodaysSpecialsLoading) return <Spinner />;

  return (
    <div className="dashboard-content">
      <div className="flex gap-1">
        <Button
          className="add-btn"
          variant="primary"
          size="sm"
          onClick={goToAdd}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="ml-2">{t(`${tKey}addTodaysSpecial`)}</span>
        </Button>
        <div className="ml-auto flex gap-1 w-80 search-module">
          <DecoratedInput
            className="bg-white dashboard-search-bar w-100"
            input={{
              id: "searchTodaysSpecial",
              class: "p4",
              value: "",
              placeholder: t(`${tKey}searchPlaceholder`, {
                count: todaysSpecials ? todaysSpecials.length : 0,
              }),
            }}
            hasResearchButton
            onResearchButtonClick={(query) => {
              setQuery(query);
            }}
          />
          <Select
            className="react-custom-select dashboard-select"
            classNamePrefix="react-select"
            placeholder={t(`${tKey}status.header`)}
            options={statusOptions}
            value={selectedStatus}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedStatus(value)}
          />
          <Select
            className="react-custom-select dashboard-select"
            classNamePrefix="react-select"
            placeholder={t(`${tKey}recurrence.header`)}
            options={recurrenceOptions}
            value={selectedRecurrence}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedRecurrence(value)}
          />
          <DownloadTable
            data={filteredTodaysSpecials}
            fileName="todaysspecials"
            fileTypes={["csv", "xlsx"]}
          />
        </div>
      </div>
      <div className="mt-4 table-container">
        {filteredTodaysSpecials?.length === 0 ? (
          <NoData />
        ) : (
          <Table
            columns={columns}
            data={filteredTodaysSpecials}
            isDroppable={!query && !selectedStatus && !selectedRecurrence}
            onOrderChange={handleOrderChange}
            onClickRow={handleDetail}
          />
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onOK={deleteSelectedTodaysSpecial}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default RestaurantTodaysSpecial;
