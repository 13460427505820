import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Form from "../../../inputs/Form";
import Spinner from "../../../utils/Spinner";
import useApi from "../../../../hooks/useApi";
import {
  creatNewMenu,
  updateMenu,
  getMenu,
  deleteMenu,
  switchActivateMenu,
} from "../../../../services/menuService";
import {
  getDishesByType,
  creatNewDish,
} from "../../../../services/dishService";
import Button from "../../../buttons/Button";
import { formatSwissPrice } from "../../../../utils/formatting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductAppPreview from "../../../app/ProductAppPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/pro-light-svg-icons";
import Modal from "../../../utils/Modal";
import useModal from "../../../../hooks/useModal";
import { useDishCreationModal } from "../../../../hooks/useDishCreationModal";
import { usePageTitle } from "../../../../hooks/useMeta";
import NavigationContext from "../../../../contexts/NavigationContext";
import DishForm from "../dish/DishForm";
import useLangNavigate from "../../../../hooks/useLangNavigate";

export const MenuView = ({ tKey = "menuView." }) => {
  const { menuId } = useParams();
  usePageTitle(menuId ? "menuView" : "newMenu");
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [dishesLists, setDishesLists] = useState({});
  const [formData, setFormData] = useState({});
  const [lockedDishTypeId, setLockedDishTypeId] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const { setHasChanged, hasChanged } = useContext(NavigationContext);
  const {
    dishFormData,
    setDishFormData,
    isDishValid,
    setIsDishValid,
    isDishModalOpen,
    openDishModal,
    closeDishModal,
  } = useDishCreationModal();
  const { request: menuCreateRequest, loading: menuCreateLoading } =
    useApi(creatNewMenu);
  const { request: menuGetRequest } = useApi(getMenu);
  const { request: menuUpdateRequest, loading: menuUpdateLoading } =
    useApi(updateMenu);
  const { request: deleteMenuRequest } = useApi(deleteMenu);
  const { request: switchActivateRequest, loading: switchActivateLoading } =
    useApi(switchActivateMenu);
  const { request: getDishesRequest } = useApi(getDishesByType);
  const { request: dishCreateRequest } = useApi(creatNewDish);

  const fetchListData = useCallback(async () => {
    setIsLoading(true);
    try {
      const startersList = await getDishesRequest(restaurantId, 3);
      const mainsList = await getDishesRequest(restaurantId, 1);
      const dessertsList = await getDishesRequest(restaurantId, 4);
      const beveragesList = await getDishesRequest(restaurantId, 2);
      setDishesLists({
        startersList: startersList.data,
        mainsList: mainsList.data,
        dessertsList: dessertsList.data,
        beveragesList: beveragesList.data,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [getDishesRequest, restaurantId, setIsLoading, setDishesLists]);

  const handleDishCreationModal = useCallback(
    (typeId) => async () => {
      setLockedDishTypeId(typeId);
      openDishModal();
    },
    [setLockedDishTypeId, openDishModal]
  );

  useEffect(() => {
    fetchListData();
  }, [fetchListData]);

  const convertMenuToFormData = useCallback(
    (menu) => {
      const starter = menu.dishes.find((dish) => dish.typeId === 3);
      const main = menu.dishes.find((dish) => dish.typeId === 1);
      const dessert = menu.dishes.find((dish) => dish.typeId === 4);
      const beverage = menu.dishes.find((dish) => dish.typeId === 2);
      setFormData({
        ...menu,
        starter: starter
          ? {
              label: starter.name,
              value: starter.dishId,
            }
          : null,
        main: main
          ? {
              label: main.name,
              value: main.dishId,
            }
          : null,
        dessert: dessert
          ? {
              label: dessert.name,
              value: dessert.dishId,
            }
          : null,
        beverage: beverage
          ? {
              label: beverage.name,
              value: beverage.dishId,
            }
          : null,
      });
    },
    [setFormData]
  );

  useEffect(() => {
    setHasChanged(false);
    if (menuId) {
      setIsLoading(true);
      menuGetRequest(menuId)
        .then((res) => {
          const menu = res.data;
          convertMenuToFormData(menu);
          const main = menu.dishes?.find((d) => d.typeId === 1);
          if (main && main.fullImagePath) setPreviewImg(main.fullImagePath);
        })
        .catch((error) => {
          console.error(error);
          toast.error(t(`${tKey}errors.getMenu`));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [menuId, menuGetRequest, setHasChanged, convertMenuToFormData, t, tKey]);

  useEffect(() => {
    setFormData((oldValue) => {
      return {
        ...oldValue,
        finalPrice: formData.price - (formData.price * formData.discount) / 100,
      };
    });
  }, [formData.price, formData.discount]);

  useEffect(() => {
    if (!hasChanged) return;
    if (formData.main?.value?.imagePath)
      setPreviewImg(formData.main.value.imagePath);
    else if (!formData.main) setPreviewImg(null);
  }, [formData.main, hasChanged, setPreviewImg]);

  useEffect(() => {
    if (
      dishesLists.startersList &&
      dishesLists.mainsList &&
      dishesLists.dessertsList &&
      dishesLists.beveragesList
    )
      setFields([
        {
          name: "name",
          placeholder: t(`${tKey}name.placeholder`),
          type: "text",
          class: "w-100",
          validate: (value) => {
            if (!value) return t(`${tKey}errors.name.required`);
            if (value.length < 2) return t(`${tKey}errors.name.min`);
            if (value.length > 50) return t(`${tKey}errors.name.max`);
            return "";
          },
        },
        {
          name: "description",
          placeholder: t(`${tKey}description.placeholder`),
          type: "textarea",
          class: "w-100",
          rows: 4,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.description.required`);
            if (value.length < 2) return t(`${tKey}errors.description.min`);
            if (value.length > 255) return t(`${tKey}errors.description.max`);
            return "";
          },
        },
        {
          name: "starter",
          placeholder: t(`${tKey}starter.placeholder`),
          type: "dropdown",
          class: "w-100",
          isClearable: true,
          options: dishesLists.startersList.map((option) => ({
            ...option,
            label: option.name,
          })),
          optionFormat: (option) => {
            return option.name;
          },
          rightElement: (
            <Button
              variant="white"
              size="sm"
              rounded
              onClick={handleDishCreationModal(3)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          ),
        },
        {
          name: "main",
          placeholder: t(`${tKey}main.placeholder`),
          type: "dropdown",
          class: "w-100",
          isClearable: true,
          options: dishesLists.mainsList.map((option) => ({
            ...option,
            label: option.name,
          })),
          validate: (value) => {
            if (!value) return t(`${tKey}errors.main.required`);
            return "";
          },
          optionFormat: (option) => {
            return option.name;
          },
          rightElement: (
            <Button
              variant="white"
              size="sm"
              rounded
              onClick={handleDishCreationModal(1)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          ),
        },
        {
          name: "dessert",
          placeholder: t(`${tKey}dessert.placeholder`),
          type: "dropdown",
          class: "w-100",
          isClearable: true,
          options: dishesLists.dessertsList.map((option) => ({
            ...option,
            label: option.name,
          })),
          optionFormat: (option) => {
            return option.name;
          },
          rightElement: (
            <Button
              variant="white"
              size="sm"
              rounded
              onClick={handleDishCreationModal(4)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          ),
        },
        {
          name: "beverage",
          placeholder: t(`${tKey}beverage.placeholder`),
          type: "dropdown",
          class: "w-100",
          isClearable: true,
          options: dishesLists.beveragesList.map((option) => ({
            ...option,
            label: option.name,
          })),
          optionFormat: (option) => {
            return option.name;
          },
          rightElement: (
            <Button
              variant="white"
              size="sm"
              rounded
              onClick={handleDishCreationModal(2)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          ),
        },
        {
          name: "price",
          placeholder: t(`${tKey}price.placeholder`),
          type: "number",
          class: "col-4",
          inputContainerClassName: "input-chf",
          validate: (value) => {
            if (!value) return t(`${tKey}errors.price.required`);
            return "";
          },
          onBlur: (event, updateFormState, field) => {
            const formattedValue = formatSwissPrice(event.target.value);
            updateFormState(field, formattedValue);
          },
        },
        {
          name: "discount",
          placeholder: t(`${tKey}discount.placeholder`),
          type: "number",
          class: "col-4 px-2",
          inputContainerClassName: "input-percent",
          validate: (value) => {
            if (value >= 100) return t(`${tKey}errors.discount.max`);
            return "";
          },
        },
        {
          name: "finalPrice",
          placeholder: t(`${tKey}finalPrice.placeholder`),
          type: "number",
          class: "col-4",
          inputContainerClassName: "input-chf",
          disabled: true,
          value: formData.finalPrice,
          valueFormat: (value) => {
            return formatSwissPrice(value);
          },
        },
      ]);
  }, [dishesLists, formData, t, tKey, handleDishCreationModal]);

  const attemptCreateMenu = useCallback(
    async (e, activate = false) => {
      e.preventDefault();
      await menuCreateRequest({
        ...formData,
        isActive: activate,
        isPublished: true,
        restaurantId,
      })
        .then(() => {
          toast.success(t(`${tKey}success`));
          navigate("dashboard/menus");
        })
        .catch((error) => {
          toast.error(t(`${tKey}error`));
          console.log(error);
        });
    },
    [formData, menuCreateRequest, navigate, restaurantId, t, tKey]
  );

  const attemptUpdateMenu = useCallback(
    async (e, activate = false) => {
      e.preventDefault();
      await menuUpdateRequest({
        ...formData,
        isActive: activate,
        isPublished: true,
        menuId,
        restaurantId,
      })
        .then(() => {
          toast.success(t(`${tKey}success`));
          navigate("dashboard/menus");
        })
        .catch((error) => {
          toast.error(t(`${tKey}error`));
          console.log(error);
        });
    },
    [formData, menuUpdateRequest, navigate, menuId, restaurantId, t, tKey]
  );

  const attemptSwitchActiveMenu = useCallback(async () => {
    if (!formData.isActive) await attemptUpdateMenu(null, true);
    await switchActivateRequest(menuId)
      .then((res) => {
        convertMenuToFormData(res.data);
        toast.success(t(`${tKey}success`));
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  }, [
    formData.isActive,
    convertMenuToFormData,
    menuId,
    switchActivateRequest,
    attemptUpdateMenu,
    t,
    tKey,
  ]);

  const attemptDelete = useCallback(() => {
    setModalContent(
      <>
        <h5 className="mt-0">{t(`${tKey}delete.title`)}</h5>
        <p>{t(`${tKey}delete.text`, { menuName: formData.name })}</p>
      </>
    );
    openModal();
  }, [formData.name, openModal, t, tKey]);

  const deleteCurrentMenu = useCallback(async () => {
    await deleteMenuRequest(menuId)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            t(`${tKey}delete.success`, { menuName: formData.name })
          );
          navigate("dashboard/menus");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}delete.error`, { menuName: formData.name }));
      })
      .finally(async () => {
        setModalContent(null);
        closeModal();
      });
  }, [closeModal, deleteMenuRequest, formData.name, menuId, navigate, t, tKey]);

  const attemptCreateDish = useCallback(async () => {
    await dishCreateRequest({
      ...dishFormData,
      isActive: false,
      isPublished: false,
      restaurantId,
    })
      .then((res) => {
        toast.success(t(`dishView.success`));
        closeDishModal();
        fetchListData();
        const newDish = res.data;
        setFormData({
          ...formData,
          [newDish.typeId === 3
            ? "starter"
            : newDish.typeId === 1
            ? "main"
            : newDish.typeId === 2
            ? "beverage"
            : "dessert"]: { label: newDish.name, value: newDish.dishId },
        });
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  }, [
    closeDishModal,
    dishCreateRequest,
    dishFormData,
    fetchListData,
    formData,
    t,
    tKey,
    setFormData,
    restaurantId,
  ]);

  return (
    <>
      <div className="dashboard-content w-50">
        {isLoading ? (
          <Spinner size="3x" variant="danger" label={t("utils.data.loading")} />
        ) : (
          <Form
            fields={fields}
            currentForm={formData}
            setFormDetails={setFormData}
            setIsValid={setIsValid}
            onInitialChange={() => setHasChanged(true)}
            fieldClass="bg-white"
          />
        )}
      </div>
      <div className="dashboard-preview w-50">
        <div className="preview-section">
          <ProductAppPreview
            img={previewImg}
            title={formData.name !== "" ? formData.name : null}
          />
        </div>
        <div className="publish-section flex align-center justify-between">
          <div>
            {menuId && (
              <Button variant="white" onClick={attemptDelete}>
                <FontAwesomeIcon size="2x" icon={faTrashAlt} />
              </Button>
            )}
          </div>
          <div className="flex gap-1">
            <Button
              disabled={!isValid}
              onClick={menuId ? attemptUpdateMenu : attemptCreateMenu}
              loading={
                menuCreateLoading || menuUpdateLoading || switchActivateLoading
              }
            >
              {t(`${tKey}${menuId ? "btnUpdate" : "btnPublish"}`)}
            </Button>
            {menuId ? (
              <Button
                variant={formData.isActive ? "danger" : "success"}
                disabled={!isValid}
                onClick={attemptSwitchActiveMenu}
                loading={
                  menuCreateLoading ||
                  menuUpdateLoading ||
                  switchActivateLoading
                }
              >
                {t(
                  `${tKey}${
                    formData.isActive ? "btnDeactivate" : "btnActivate"
                  }`
                )}
              </Button>
            ) : (
              <Button
                variant="success"
                disabled={!isValid}
                onClick={
                  menuId
                    ? (e) => attemptUpdateMenu(e, true)
                    : (e) => attemptCreateMenu(e, true)
                }
                loading={
                  menuCreateLoading ||
                  menuUpdateLoading ||
                  switchActivateLoading
                }
              >
                {t(`${tKey}${menuId ? "btnActivate" : "btnPublishActivate"}`)}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteCurrentMenu}>
        {modalContent}
      </Modal>
      <Modal
        id="modal-dish"
        isOpen={isDishModalOpen}
        onClose={closeDishModal}
        noFooter
      >
        <DishForm
          fieldClass="bg-pastel-light"
          formData={dishFormData}
          onChange={setDishFormData}
          lockedTypeId={lockedDishTypeId}
          setIsValid={setIsDishValid}
        />
        <div className="flex justify-end">
          <Button disabled={!isDishValid} onClick={attemptCreateDish}>
            {t("dishView.btnPublish")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MenuView;
