import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getAllCompanies } from "../../../services/admin/companyService";
import { getAllRestaurants } from "../../../services/admin/restaurantService";
import useApi from "../../../hooks/useApi";
import useLangNavigate from "../../../hooks/useLangNavigate";
import { usePageTitle } from "../../../hooks/useMeta";
import DecoratedInput from "../../inputs/DecoratedInput";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { createCompanyIcon, createRestaurantIcon } from "../../../utils/gis";
import Spinner from "../../utils/Spinner";

export const AdminMap = ({ tKey = "dashbaord.adminMap." }) => {
  usePageTitle("adminMap");
  const [companies, setCompanies] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [center, setCenter] = useState([46.8307489, 8.3252039]);
  const [zoom, setZoom] = useState(8);
  const [query, setQuery] = useState("");
  const { request: getAllCompaniesRequest, loading: companiesLoading } =
    useApi(getAllCompanies);
  const { request: getAllRestaurantsRequest, loading: restaurantsLoading } =
    useApi(getAllRestaurants);
  const mapRef = useRef(null);
  const navigate = useLangNavigate();
  const { t } = useTranslation();

  const setMapView = useCallback((x, y, newZoom) => {
    if (!mapRef.current) return;
    mapRef.current.setView([x, y], newZoom);
  }, []);

  function LocationMarker() {
    const map = useMapEvents({
      moveend: () => {
        setCenter(map.getCenter());
        setZoom(map.getZoom());
      },
    });

    return null;
  }

  useEffect(() => {
    getAllCompaniesRequest().then((response) => {
      setCompanies(response.data);
    });
    getAllRestaurantsRequest().then((response) => {
      setRestaurants(response.data);
    });
  }, [getAllCompaniesRequest, getAllRestaurantsRequest]);

  useEffect(() => {
    setFilteredCompanies(
      companies.filter((company) =>
        company.name.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilteredRestaurants(
      restaurants.filter((restaurant) =>
        restaurant.name.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [companies, restaurants, query]);

  const handleSelectCompany = useCallback(
    (company) => () => {
      setMapView(company.x, company.y, 18);
    },
    [setMapView]
  );

  const handleSelectRestaurant = useCallback(
    (restaurant) => () => {
      setMapView(restaurant.x, restaurant.y, 18);
    },
    [setMapView]
  );

  if (companiesLoading || restaurantsLoading) return <Spinner />;

  return (
    <div className="dashboard-content flex column gap-2">
      <DecoratedInput
        className="bg-white dashboard-search-bar w-100"
        input={{
          id: "searchProduct",
          class: "p4",
          value: "",
          placeholder: t(`${tKey}searchPlaceholder`),
        }}
        hasResearchButton
        onResearchButtonClick={(query) => {
          setQuery(query);
        }}
      />
      <div className="col">
        <MapContainer
          ref={mapRef}
          center={center}
          zoom={zoom}
          style={{ height: "100%", width: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker />
          {filteredCompanies.map((company) => (
            <Marker
              icon={createCompanyIcon()}
              key={company.companyId}
              position={[company.x, company.y]}
              eventHandlers={{
                click: handleSelectCompany(company),
              }}
            >
              <Popup>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`dashboard/admin-companies/${company.companyId}`)
                  }
                >
                  <div className="flex gap-2">
                    {company.imagePath && (
                      <img
                        className="img-contain rounded"
                        src={company.fullImagePath}
                        alt={company.name}
                        width={120}
                      />
                    )}
                    <div className="flex column gap-1">
                      <span className="p1">{company.name}</span>
                      <span>{company.address}</span>
                      <span>
                        {company.city.npa} {company.city.label}
                      </span>
                      <span>
                        <a href={`mailto:${company.email}`}>{company.email}</a>
                      </span>
                      <span>{company.phone}</span>
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
          {filteredRestaurants.map((restaurant) => (
            <Marker
              icon={createRestaurantIcon()}
              key={restaurant.restaurantId}
              position={[restaurant.x, restaurant.y]}
              eventHandlers={{
                click: handleSelectRestaurant(restaurant),
              }}
            >
              <Popup>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(
                      `dashboard/admin-restaurants/${restaurant.restaurantId}`
                    )
                  }
                >
                  <div className="flex gap-2">
                    {!!restaurant.images.length && (
                      <img
                        className="img-contain"
                        src={restaurant.images?.[0]?.fullImagePath}
                        alt={restaurant.name}
                        width={120}
                      />
                    )}
                    <div className="flex column gap-1">
                      <span className="p1">{restaurant.name}</span>
                      <span>{restaurant.address}</span>
                      <span>
                        {restaurant.city.npa} {restaurant.city.label}
                      </span>
                      <span>
                        <a href={`mailto:${restaurant.email}`}>
                          {restaurant.email}
                        </a>
                      </span>
                      <span>{restaurant.phone}</span>
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default AdminMap;
