import React, { useEffect } from "react";
import Spinner from "../utils/Spinner";

function Button({
  children,
  className,
  size = "md",
  variant = "primary",
  icon = false,
  rounded = false,
  block = false,
  outlined = false,
  link = false,
  floating = false,
  disabled = false,
  loading = false,
  onClick,
  onEnter = null,
}) {
  let buttonClass = link
    ? `btn-link ${block ? "btn-block" : ""} link-${size}`
    : `btn-${variant}${outlined ? "-outlined" : ""} btn-${size} ${
        rounded ? "btn-rounded" : ""
      } ${icon ? "btn-icon" : ""} ${block ? "btn-block" : ""} ${
        floating ? "btn-floating" : ""
      }`;
  buttonClass += ` ${className}`;
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        onEnter && onEnter(event);
      }
    };
    if (onEnter) {
      document.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [onEnter]);
  return (
    <button
      className={`flex align-center ${buttonClass}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? <Spinner label={children} /> : children}
    </button>
  );
}

export default Button;
