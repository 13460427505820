import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ConfirmAccount from "./pages/auth/ConfirmAccount";
import ConfirmPassword from "./pages/auth/ConfirmPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangeForgotPassword from "./pages/auth/ChangeForgotPassword";
import Dashboard from "./pages/Dashboard";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import ConsentAndCookies from "./pages/legal/ConsentAndCookies";
import LegalMentions from "./pages/legal/LegalMentions";
import NotFound from "./pages/errors/NotFound";
import Cookie from "./components/utils/Cookie";
import LanguageLogic from "./components/HOC/LanguageLogic";

const App = () => {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Cookie />
        <Routes>
          <Route path="/" element={<LanguageLogic />} />
          <Route
            path=":lang/*"
            element={
              <LanguageLogic>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="register/:referralCode" element={<Register />} />
                  <Route
                    path="confirmAccount/:activationCode"
                    element={<ConfirmAccount />}
                  />
                  <Route path="forgotPassword" element={<ForgotPassword />} />
                  <Route
                    path="changeForgotPassword/:username/:forgotPasswordToken"
                    element={<ChangeForgotPassword />}
                  />
                  <Route
                    path="resetPassword/:username"
                    element={<ChangeForgotPassword />}
                  />
                  <Route
                    path="confirmPassword/:username"
                    element={<ConfirmPassword />}
                  />
                  <Route path="dashboard/*" element={<Dashboard />} />
                  <Route
                    path="termsAndConditions"
                    element={<TermsAndConditions />}
                  />
                  <Route
                    path="consentAndCookies"
                    element={<ConsentAndCookies />}
                  />
                  <Route path="legalMentions" element={<LegalMentions />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </LanguageLogic>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
