import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getRestaurant,
  getRestaurantOrders,
} from "../../../../services/admin/restaurantService";
import ReactApexChart from "react-apexcharts";
import useApi from "../../../../hooks/useApi";
import useLangNavigate from "../../../../hooks/useLangNavigate";
import NoData from "../../../errors/NoData";
import Table from "../../../table/Table";
import Button from "../../../buttons/Button";
import { startControllingRestaurant } from "../../../../redux/slices/adminSlice";
import { useDispatch } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { format, parse } from "date-fns";
import { createCompanyIcon, createRestaurantIcon } from "../../../../utils/gis";
import Spinner from "../../../utils/Spinner";

const RestaurantView = ({ tKey = "dashboard.restaurantView." }) => {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { request: getRestaurantRequest, loading } = useApi(getRestaurant);
  const { request: getRestaurantOrdersRequest } = useApi(getRestaurantOrders);
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [partners, setPartners] = useState([]);
  const [ordersChartSeries, setOrdersChartSeries] = useState([]);
  const [ordersChartLabels, setOrdersChartLabels] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const mapRef = useRef(null);
  const [center, setCenter] = useState([46.8307489, 8.3252039]);
  const [zoom, setZoom] = useState(8);
  const dispatch = useDispatch();

  const setMapView = useCallback((x, y, newZoom) => {
    if (!mapRef.current) return;
    mapRef.current.setView([x, y], newZoom);
  }, []);

  useEffect(() => {
    getRestaurantRequest(restaurantId).then((res) => {
      setRestaurant(res.data);
      setMapView(res.data.x, res.data.y, 13);
      setPartners(res.data.offers.map((offer) => offer.partners).flat());
    });
    getRestaurantOrdersRequest(restaurantId).then((res) => {
      const groupedOrders = {};
      res.data.forEach((order) => {
        const date = format(new Date(order.startAt), "MMM yyyy");
        if (!groupedOrders[date]) groupedOrders[date] = [];
        groupedOrders[date].push(order);
      });

      const sortedKeys = Object.keys(groupedOrders).sort((a, b) => {
        const yearA = a.split(" ")[1],
          monthA = a.split(" ")[0];
        const yearB = b.split(" ")[1],
          monthB = b.split(" ")[0];
        const dateA = parse(`${monthA} ${yearA}`, "MMM yyyy", new Date());
        const dateB = parse(`${monthB} ${yearB}`, "MMM yyyy", new Date());
        return dateA - dateB;
      });

      const chartData = sortedKeys.reduce(
        (acc, key) => {
          const monthTotal = groupedOrders[key].length;
          const monthAmount = groupedOrders[key].reduce(
            (acc, order) => acc + order.totalPrice,
            0
          );
          acc.cumulativeTotalData.push((acc.cumulativeTotal || 0) + monthTotal);
          acc.cumulativeAmountData.push(
            (acc.cumulativeAmount || 0) + monthAmount
          );
          acc.monthlyTotalData.push(monthTotal);
          acc.monthlyAmountData.push(monthAmount);
          acc.cumulativeTotal += monthTotal;
          acc.cumulativeAmount += monthAmount;
          return acc;
        },
        {
          cumulativeTotalData: [],
          cumulativeAmountData: [],
          monthlyTotalData: [],
          monthlyAmountData: [],
          cumulativeTotal: 0,
          cumulativeAmount: 0,
        }
      );

      setOrdersChartSeries([
        {
          name: t(`${tKey}orders.totalCumul`),
          type: "line",
          data: chartData.cumulativeTotalData,
        },
        {
          name: t(`${tKey}orders.amountCumul`),
          type: "line",
          data: chartData.cumulativeAmountData,
        },
        {
          name: t(`${tKey}orders.totalMonthly`),
          type: "column",
          data: chartData.monthlyTotalData,
        },
        {
          name: t(`${tKey}orders.amountMonthly`),
          type: "column",
          data: chartData.monthlyAmountData,
        },
      ]);
      setOrdersChartLabels(sortedKeys);
      setTotalOrdersCount(chartData.cumulativeTotal);
    });
  }, [
    t,
    tKey,
    restaurantId,
    setMapView,
    getRestaurantRequest,
    getRestaurantOrdersRequest,
  ]);

  const ordersChartOptions = useMemo(
    () => ({
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [4, 4, 0, 0],
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: ordersChartLabels,
      xaxis: {
        type: "category",
        categories: ordersChartLabels,
        tickPlacement: "on",
      },
      yaxis: [
        {
          title: {
            text: t(`${tKey}orders.count`),
            style: {
              color: "#FFC117",
            },
          },
          labels: {
            formatter: (val) => `${parseInt(val, 10)}`,
            style: {
              colors: "#FFC117",
            },
          },
        },
        {
          opposite: true,
          title: {
            text: t(`${tKey}orders.sellings`),
            style: {
              color: "#5e8347",
            },
          },
          labels: {
            formatter: (val) => `${parseFloat(val).toFixed(2)} CHF`,
            style: {
              colors: "#5e8347",
            },
          },
        },
      ],
      tooltip: {
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return parseInt(val, 10);
          },
        },
      },
      colors: ["#b39a10", "#5e8347", "#FFC117", "#85BB65"],
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: -10,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    }),
    [ordersChartLabels, t, tKey]
  );

  const offersColumns = useMemo(
    () => [
      {
        accessor: "offerId",
        Cell: "",
      },
      {
        Header: t(`${tKey}name`),
        accessor: (row) => t(`utils.offerTypes.${row.offertype.label}`),
      },
      {
        Header: t(`${tKey}partners`),
        accessor: (row) => (
          <span>
            {t(`${tKey}partnersCount`, {
              count: row.partners.length,
              max: row.maximumSubscribers,
            })}
          </span>
        ),
      },
    ],
    [t, tKey]
  );

  function LocationMarker() {
    const map = useMapEvents({
      moveend: () => {
        setCenter(map.getCenter());
        setZoom(map.getZoom());
      },
    });
    return null;
  }

  const handleRestaurantControl = useCallback(
    (row) => {
      dispatch(
        startControllingRestaurant({
          ...row,
          imagePath: row.images?.find((i) => i.isMainImage)?.imagePath,
        })
      );
      navigate("dashboard");
    },
    [dispatch, navigate]
  );

  if (loading) return <Spinner />;

  return (
    <div className="dashboard-content flex column gap-2 overflow-y-scroll">
      <div className="flex justify-between">
        <div className="flex column">
          <h2 className="m-0 flex gap-2">
            <span>{restaurant?.name}</span>
            <Button
              size="sm"
              onClick={() => handleRestaurantControl(restaurant)}
            >
              {t(`${tKey}takeControl`)}
            </Button>
          </h2>
          <div>
            {t(`${tKey}employees`, { count: restaurant?.employees.length })}
          </div>
          <div className="flex column p3">
            <a href={`mailto:${restaurant?.email}`}>{restaurant?.email}</a>
            <span>{restaurant?.phone}</span>
            <span>{`${restaurant?.address}, ${restaurant?.city.npa} ${restaurant?.city.label}`}</span>
          </div>
        </div>
        {!!restaurant?.images.length > 0 && (
          <img
            height={100}
            src={restaurant?.images?.[0]?.fullImagePath}
            alt="Restaurant Logo"
          />
        )}
      </div>
      <div className="col flex gap-2">
        <div className="col-6 flex column bg-white rounded p-3">
          <div className="col bg-white rounded p-3">
            <h3 className="m-0">
              {t(`${tKey}partners`, {
                count: restaurant?.offers.length,
              })}
            </h3>
            {restaurant?.offers.length > 0 ? (
              <Table
                columns={offersColumns}
                data={restaurant?.offers}
                rowClassName="p-2"
              ></Table>
            ) : (
              <div>{<NoData />}</div>
            )}
          </div>
          <div className="col bg-white rounded p-3">
            <h3 className="m-0">{t(`${tKey}orders.title`)}</h3>
            <ReactApexChart
              key={totalOrdersCount}
              options={ordersChartOptions}
              series={ordersChartSeries}
              type="line"
              height={300}
            />
          </div>
        </div>
        <div className="col-6 flex column bg-white rounded p-3">
          <MapContainer
            ref={mapRef}
            center={center}
            zoom={zoom}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
              OpenStreetMap</a> contributors'
            />
            <LocationMarker />
            {!!restaurant && (
              <Marker
                icon={createRestaurantIcon()}
                position={[restaurant.x, restaurant.y]}
              >
                <Popup>{restaurant?.name}</Popup>
              </Marker>
            )}
            {!!partners &&
              partners.length > 0 &&
              partners.map((partner) => (
                <Marker
                  icon={createCompanyIcon()}
                  key={partner.companyId}
                  position={[partner?.x || 0, partner?.y || 0]}
                >
                  <Popup>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `dashboard/admin-companies/${partner.companyId}`
                        )
                      }
                    >
                      {partner.name}
                    </div>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default RestaurantView;
