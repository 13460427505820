import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getListOptions } from '../../../services/listService';
import { toast } from 'react-toastify';
import Spinner from '../../utils/Spinner';
import Form from '../../inputs/Form';
import { isValidEmail } from '../../../utils/formatting';

function StepFiveRestaurant({ currentForm, setBankDetails, setIsValid = () => {} }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([])
  const [fields, setFields] = useState([])

  useEffect(() => {
    const fetchCitiesData = async () => {
      setIsLoading(true);
      try {
        const citiesList = await getListOptions('city');
        setCityOptions(citiesList.data);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCitiesData();
  }, [])

  useEffect(() => {
    if (cityOptions && cityOptions.length > 0)
      setFields([
        {
          name: 'legalName', 
          label: t('register.step5.legalName.label'),
          placeholder: t('register.step5.legalName.placeholder'),
          type: 'text',
          class: 'w-50 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.legalName.required');
            if (value.length > 100) return t('register.errors.legalName.max');
            return '';
          }
        },
        {
          name: 'billingEmail',
          label: t('register.step5.billingEmail.label'),
          placeholder: t('register.step5.billingEmail.placeholder'),
          type: 'email',
          class: 'w-50 pl-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.billingEmail.required');
            if (!isValidEmail(value)) return t('register.errors.email.invalid');
            return '';
          }
        },
        {
          name: 'billingAddress', 
          label: t('register.step5.billingAddress.label'),
          placeholder: t('register.step5.billingAddress.placeholder'),
          type: 'text',
          class: 'w-60 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.address.required');
            if (value.length > 100) return t('register.errors.address.max');
            return '';
          },
        },
        {
          name: 'billingCity',
          label: t('register.step5.billingCity.label'),
          placeholder: t('register.step5.billingCity.placeholder'),
          type: 'dropdown',
          class: 'w-40 pl-2',
          options: cityOptions,
          validate: (value) => {
            if (!value) return t('register.errors.billingCity.required');
            return '';
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `
          },
        },
      ])
    setIsValid(true);
  }, [cityOptions, setIsValid, t]);

  const handleBankDetails = (data) => {
    setBankDetails(data);
  };

  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.step5.title')}</h2>
      </div>
      {isLoading 
        ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
        : <Form fields={fields} currentForm={currentForm} setFormDetails={handleBankDetails} setIsValid={setIsValid} />}
        <div dangerouslySetInnerHTML={{__html: t('register.step5.details')}}></div>
    </div>
  );
}

export default StepFiveRestaurant;