import React, { useState, useEffect } from "react";
import Tag from "../utils/Tag";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";

const RestaurantAppPreview = ({
  img = null,
  bottomClass = "",
  restaurantType = null,
  title = "Name",
  subtitle = "... km",
  number = 10,
  chip = null,
  isAvatar = false,
  size = null,
  onClick = () => {},
}) => {
  const [imgPath, setImgPath] = useState("");

  useEffect(() => {
    if (!img && restaurantType)
      import(`../../assets/img/restaurantTypes/${restaurantType.label}.svg`)
        .then((image) => setImgPath(image.default))
        .catch((error) => {
          console.error("Failed to load image:", error);
          toast.error("Failed to load image");
        });
  }, [img, restaurantType]);

  if (isAvatar) {
    return (
      <div onClick={onClick}>
        <img
          src={
            img
              ? img instanceof File
                ? URL.createObjectURL(img)
                : img
              : imgPath
          }
          className="user-avatar"
          alt="restaurant"
          style={{ width: `${size || 100}px`, height: `${size || 100}px` }}
        />
      </div>
    );
  }
  return (
    <div
      className={`app-preview-card ${size ? "very-rounded no-shadow" : ""}`}
      style={{
        ...(size && { width: `${size}px`, height: `${size}px` }),
      }}
    >
      <div
        className={`app-preview-img`}
        style={{
          backgroundImage: `url(${
            img
              ? img instanceof File
                ? URL.createObjectURL(img)
                : img
              : imgPath
          })`,
          ...(size && { width: `${size}px`, height: `${size}px` }),
        }}
      >
        {chip && <Tag text={chip} />}
      </div>
      <div className={`app-preview-text ${bottomClass}`}>
        <div className="w-80">
          <h4>{title}</h4>
          <div className="p4">{subtitle}</div>
        </div>
        <div className="p3">
          <FontAwesomeIcon icon={faStar} className="mr-1" />
          <span>{number}</span>
        </div>
      </div>
    </div>
  );
};

export default RestaurantAppPreview;
